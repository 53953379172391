export interface VaultStyle {
  name: string
  color: string
  priority: number
}

export interface VaultStyleData {
  id: number
  name: string
  color: string
  priority: number
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
}

export interface VaultStyleResponse {
  data: VaultStyleData
}

export interface VaultStyleListResponse {
  data: VaultStyleData[]
}

export const initialVaultStyleState = {
  name: '',
  color: '',
  priority: 0
}
