import axios from 'axios'
import { RANKED_API_URL } from '@config/environment'

// SHARED INTERCEPTORS
import { _shared } from '../../../../../root/src/app/shared/shared'
const shared = _shared

const rankedApi = axios.create({
  baseURL: RANKED_API_URL,
})

rankedApi.interceptors.request.use(shared.authInterceptor)
rankedApi.interceptors.response.use(
  shared.responseInterceptor,
  shared.checkTokenValidityInterceptor
)

export { rankedApi }
