import Swal, { SweetAlertResult } from 'sweetalert2'

export const success = (successMessage: string, callback?: void): void => {
  Swal.fire({
    title: successMessage,
    icon: 'success',
    confirmButtonText: 'OK',
    confirmButtonColor: '#1976d2',
    didClose: (): void => callback,
  })
}

export const error = (errorMessage: string): void => {
  Swal.fire({
    title: errorMessage,
    icon: 'error',
    confirmButtonText: 'OK',
    confirmButtonColor: '#1976d2',
  })
}

export const remove = (removeMessage: string): Promise<SweetAlertResult<any>> => {
  return new Promise(
    (resolve, reject) => {
      resolve(
        Swal.fire({
          title: removeMessage,
          showCancelButton: true,
          confirmButtonText: 'EXCLUIR',
          cancelButtonText: 'CANCELAR',
          confirmButtonColor: '#d32f2f',
        })
      )
    }
  )
}
