import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import {
  Chip,
  TextField,
  styled,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Card,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { LoadingButton } from '@mui/lab'
import { NotFound } from '@components/shared/NotFound/NotFound'

// ICONS
import { Storefront, PersonSearch } from '@mui/icons-material'

// SERVICES
import { getAllItems } from '@services/Items/ItemsService'

// TYPES
import { GetAllItemResponse, Item, ItemTypeEnum } from '@shared/types/ItemsTypes'

// STYLES
import * as Styled from './MarketplaceItemsList.styles'
import { purple } from '@mui/material/colors'

const MarketplaceItemsList = (): JSX.Element => {
  const [items, setItems] = useState<Item[]>()
  const [filteredItems, setFilteredItems] = useState<Item[]>()
  const [currentType, setCurrentType] = useState<string | number>('')
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [types, setTypes] = useState<number[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    _getAllItems()
  }, [])

  const _getAllItems = async (): Promise<void> => {
    try {
      setLoading(true)
      const itemsData: GetAllItemResponse = await getAllItems()
      setItems(itemsData.items)
      setRowCount(itemsData.items.length)

      const itemsTypes: number[] = []
      itemsData.items.map((item) => {
        !itemsTypes.includes(item.typeItem) &&
          itemsTypes.push(item.typeItem)
      })

      setTypes(itemsTypes)
    } finally {
      setLoading(false)
    }
  }

  const handleSeeAll = (): void => {
    setCurrentType('')
    setFilteredItems([])
    _getAllItems()
  }

  const _handleProductFiltering = (typeItem: number): void => {
    setCurrentType(typeItem)
    const filtered = items.filter((item) => item.typeItem == typeItem)
    setFilteredItems(filtered)
    setRowCount(filtered.length)
  }

  const renderItemImage = (itemImage): JSX.Element | null => {
    if (!itemImage.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Item"
        src={itemImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderItemSlug = (slug: string): JSX.Element => {
    return <Chip label={slug} color="default" />
  }

  const CustomChip = styled(Chip)(({ theme }) => ({
    color: 'white',
  }))

  const renderTypeItem = (typeItem: number): JSX.Element => {
    return (
      <CustomChip
        label={typeItem > 1 ? 'coin' : 'product'}
        variant="filled"
        color={typeItem > 1 ? 'warning' : 'success'}
      />
    )
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <Button
        onClick={(): void => navigate(`/apps/vault/items/marketplace/${id}`)}
        color="primary"
        variant="contained"
      >
        Editar
      </Button>
    )
  }

  const _renderTypesSelection = (): JSX.Element => {
    if (!types.length) return <></>

    return (
      <FormControl>
        <InputLabel id="types-label">Filtro por tipo</InputLabel>
        <Select
          labelId="types-label"
          label="Filtro por tipo"
          value={currentType}
          onChange={(e): void => _handleProductFiltering(Number(e.target.value))}
        >
          {types.map((typeItem) => {
            return <MenuItem key={typeItem} value={typeItem}>{ItemTypeEnum[typeItem]}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  const columns: GridColumns = [
    {
      field: 'image',
      headerName: 'Link do produto',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => renderItemImage(params.row.image),
    },
    {
      field: 'name',
      headerName: 'Nome',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'typeItem',
      headerName: 'Tipo',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => renderTypeItem(params.row.typeItem)
    },
    {
      field: 'slug',
      headerName: 'Slug',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => renderItemSlug(params.row.slug)
    },
    {
      field: 'quantity',
      headerName: 'Quantidade',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'stock',
      headerName: 'Estoque',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Ações',
      disableColumnMenu: true,
      flex: 0,
      sortable: false,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  const _renderDataGrid = (): JSX.Element => {
    return (
      <>
    {items?.length > 0 ? (
      <Card
        sx={{
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none',
          },
        }}
      >
      <DataGrid
        paginationMode="client"
        pageSize={10}
        rowCount={rowCount}
        rows={
          filteredItems && filteredItems.length ? filteredItems : items
        }
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        loading={loading}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[10]}
        autoHeight
        rowHeight={60}
        disableSelectionOnClick
        sx={{
          ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
            outline: 'none',
          },
          ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
            whiteSpace: 'break-spaces',
          },
        }}
      />
      </Card>
    ) : (
      <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
    )}
    </>
    )
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Itens do marketplace">
        <Storefront fontSize="large" style={{ fill: purple[500] }} />
      </HeaderTitle>
      <Styled.SearchForm>
        {_renderTypesSelection()}
        {currentType !== '' && 
          <LoadingButton
            variant="contained"
            onClick={(): void => handleSeeAll()}
            disabled={loading}
            loading={loading}
          >
            <PersonSearch /> Ver todos os itens
          </LoadingButton>
        }
      </Styled.SearchForm>
      {_renderDataGrid()}
    </Styled.Container>
  )
}

export { MarketplaceItemsList }
