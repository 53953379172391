// API
import { vaultApi } from '@shared/api/Vault/VaultApi'

// TYPES
import {
  GetTradesResponse,
  GetStatusTradesResponse,
} from '@components/Players/PlayerTradeList/types'

const getTrades = (id: string): Promise<GetTradesResponse> => {
  return vaultApi.get(`/api/user/trades/${id}`)
}

const getTradeStatus = (id: string): Promise<GetStatusTradesResponse> => {
  return vaultApi.get(`/api/trade/status/?neshaTradeId=${id}`)
}

export { getTrades, getTradeStatus }
