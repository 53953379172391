import { useEffect, useState } from 'react'

// SERVICES
import { updateSkins } from '@services/Vault/VaultService'

// COMPONENTS
import { Button, Grid } from '@mui/material'

// TYPES 
import { UpdateSkinsButtonProps } from './types'
const UpdateSkinsButton = ({
  vaultId,
  handleAlert
}: UpdateSkinsButtonProps): JSX.Element => {
  const [
    blockActions,
    setBlockActions
  ] = useState(false)


  const handleUpdateSkins = () => {
    updateSkins(vaultId)
    .then(() => {
      handleAlert('SUCCESS', vaultId)
      setBlockActions(true)
    })
    .catch(err => {
      handleAlert('FAILURE')
    }).finally(() => {
      setBlockActions(false)
    })
  }

  return (
    <Grid container>
      <Grid item>
        <Button
          type="button"
          variant="contained"
          size="small"
          sx={{ mb: 1 }}
          onClick={() => handleUpdateSkins()}
          disabled={blockActions}
          >
          Atualizar skins
        </Button>
      </Grid>
    </Grid>
  )
}

export { UpdateSkinsButton }
