export interface Item {
  description: string;
  image: string;
  name: string;
  quantity: number;
  slug: string;
  stock: number;
  typeItem: number;
}

export interface ItemResponse {
  item: Item
  errors: string[]
  statusCode: number
}

export interface GetAllItemResponse {
  items: Item[]
  errors: string[]
  statusCode: number
}

export interface CreateItemPayload {
  name: string;
  description: string;
  stock: number;
  quantity: number;
  image: string;
  slug: string;
  tier?: number;
  typeItem: number;
}

export interface UpdateItemPayload extends CreateItemPayload {
  id: string;
}

export enum ItemTypeEnum {
  SKIN = 0,
  DIGITAL = 1,
  GOLD = 2,
  SILVER = 3
}
