import * as React from 'react'

import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ModalProps } from './types'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const Modal = ({ handleClose, open, params }: ModalProps): JSX.Element => {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Status da troca
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>ID: {params?.id}</Typography>
          <Typography gutterBottom>Status: {params?.status}</Typography>
          {params?.status_error && (
            <Typography gutterBottom>
              Tipo de erro: {params?.status_error}
            </Typography>
          )}
          <Typography gutterBottom>
            Mensagem de erro: {params?.status_error_message}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

export { Modal }
