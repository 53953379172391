import styled from 'styled-components'

export const VaultTiers = styled.section`
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #eee;
  padding: 20px 20px 10px;
`

export const VaultTiersHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const VaultTiersTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #666;
  margin: 0;
`

export const VaultTiersAdd = styled.button`
  display: block;
  background: #5cb85c;
  border: 0;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`

export const VaultTiersList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const VaultTiersItem = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-of-type {
    border: 0;
  }
`
export const VaultTiersLabel = styled.label`
  display: block;
  color: #666;
`

export const VaultTiersInput  = styled.input`
  display: inline-block;
  max-width: 60px;
  margin: 0 5px;
  padding: 3px;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: transparent;
  border-width: 0 0 1px 0;
  border-color: #ccc;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const VaultTiersAttention = styled.span`
  font-weight: bold;
  color: #d9534f;
`

export const VaultTiersDelete = styled.button`
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border: 2px solid #d9534f;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  color: #d9534f;
  cursor: pointer;

  &:hover {
    background-color: #d9534f;
    color: white;
  }
`
export const VaultTiersNote = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
  border-top: 1px solid #eee;
  padding-top: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #d9534f;
`
