// API
import { vaultApi } from '@shared/api/Vault/VaultApi'
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'
import { rankedApi } from '@shared/api/Ranked/RankedApi'

// TYPES
import { 
  VaultsResponse, 
  VaultTypeResponse, 
  CosmeticsItemsResponse,
  FetchSkins,
  CreateVaultPayload,
  GetVaultInfo,
  Vaults,
  UpdateVaultPayload,
  VaultStyle,
  GetWaitingList,
  DeliveryVaultPayload,
  DeliveryVaultResponse
} from '@shared/types/VaultTypes'
import { GetProductsResponse } from '@shared/types/ProductTypes'
import { Ranked } from '@shared/types/RankedTypes'

const getVaults = (data?): Promise<VaultsResponse> => {
  let queryParams = data ? {...data, orderTo: 'backoffice'} : { orderTo: 'backoffice' }
  const queryString = new URLSearchParams(queryParams).toString()

  return vaultApi.get(`/api/vault?${queryString}`)
}

const getProducts = (data?): Promise<GetProductsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}

const getVaultType = (): Promise<VaultTypeResponse> => {
  return vaultApi.get('/api/vaultType/')
}

const getCosmetics = (data?): Promise<CosmeticsItemsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return vaultApi.get(`/api/item${queryString}`)
}

const fetchSkins = (vaultBots: number[]): Promise<FetchSkins> => {
  return vaultApi.get(`/api/skin/${vaultBots[0]}`)
}

const updateSkins = (vaultId: string): Promise<GetVaultInfo> => {
  return vaultApi.post(`/api/skin/${vaultId}/update`)
}

const createVault = (data: CreateVaultPayload): Promise<VaultsResponse> => {
  return vaultApi.post('/api/vault/', data)
}

const updateVault = (data: UpdateVaultPayload): Promise<VaultsResponse> => {
  return vaultApi.put(`/api/vault/${data.id}`, data)
}

const getVaultInfo = (id: string): Promise<GetVaultInfo> => {
  return vaultApi.get(`/api/vault/${id}`)
}

const deleteVault = (id: string): Promise<Vaults> => {
  return vaultApi.delete(`/api/vault/${id}`)
}

const getVaultStyles = (): Promise<VaultStyle[]> => {
  return vaultApi.get(`/api/vaultStyle`)
}

const getWaitingList = (playerId: string): Promise<GetWaitingList[]> => {
  return vaultApi.get(`/api/waitingList/${playerId}`)
}

const removeFromWaitingList = (data): Promise<void> => {
  return vaultApi.post(`/api/waitingList/remove`, data)
}

const deliveryVault = (
  data: DeliveryVaultPayload
): Promise<DeliveryVaultResponse> => {
  return vaultApi.post('/api/vault/drop', data)
}

const getRankeds = (): Promise<Ranked[]> => {
  const CSGO_ID = 1
  return rankedApi.get(`/v1/rankeds?gameId=${CSGO_ID}`)
}


export { 
  getVaults, 
  getProducts, 
  getVaultType, 
  getCosmetics,
  fetchSkins,
  updateSkins,
  createVault,
  updateVault,
  getVaultInfo,
  deleteVault,
  getVaultStyles,
  getWaitingList,
  removeFromWaitingList,
  deliveryVault,
  getRankeds
}
