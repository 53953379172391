import { PlayerTradeList } from '@components/Players/PlayerTradeList/PlayerTradeList'
import { LastDropsList } from '@components/LastDropsList/LastDropsList'
import { VaultDeliveryForm } from '@components/Players/VaultDelivery/VaultDelivery'
import { VaultDeliveryToTwitchForm } from '@components/Players/VaultDeliveryToTwitch/VaultDeliveryToTwitch'
import { DropVaultConfig } from '@components/DropVaultConfig/DropVaultConfig'
import { DropVaultConfigList } from '@components/DropVaultConfig/DropVaultConfigList/DropVaultConfigList'
import { VaultList } from '@components/Vault/VaultList/VaultList'
import { VaultForm } from '@components/Vault/VaultForm/VaultForm'
import { CoinsItemsForm } from '@components/Items/CoinsItemsForm/CoinsItemsForm'
import { MarketplaceItemsForm } from '@components/Items/MarketplaceItemsForm/MarketplaceItemsForm'
import { MarketplaceItemsList } from '@components/Items/MarketplaceItemsList/MarketplaceItemsList'
import { VaultStylesList } from '@components/VaultStyles/VaultStylesList/VaultStylesList'
import { VaultStylesForm } from '@components/VaultStyles/VaultStylesForm/VaultStylesForm'
import { WaitingList } from '@components/Vault/WaitingList/WaitingList'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  {
    component: <VaultList />,
    exact: true,
    path: '/apps/vault/list',
  },
  {
    component: <VaultForm />,
    exact: true,
    path: '/apps/vault/register',
  },
  {
    component: <VaultForm />,
    exact: true,
    path: '/apps/vault/:id',
  },
  {
    component: <LastDropsList />,
    exact: true,
    path: '/apps/vault/last-drops',
  },
  {
    component: <MarketplaceItemsList />,
    exact: true,
    path: '/apps/vault/items/list',
  },
  {
    component: <MarketplaceItemsForm />,
    exact: true,
    path: '/apps/vault/items/marketplace/register',
  },
  {
    component: <MarketplaceItemsForm />,
    exact: true,
    path: '/apps/vault/items/marketplace/:id',
  },
  {
    component: <CoinsItemsForm />,
    exact: true,
    path: '/apps/vault/items/coins/register',
  },
  {
    component: <CoinsItemsForm />,
    exact: true,
    path: '/apps/vault/items/coins/:id',
  },
  {
    component: <PlayerTradeList />,
    exact: true,
    path: '/apps/vault/players/trade/list',
  },
  {
    component: <VaultDeliveryForm />,
    exact: true,
    path: '/apps/vault/delivery',
  },
  {
    component: <VaultDeliveryToTwitchForm />,
    exact: true,
    path: '/apps/vault/delivery/twitch',
  },
  {
    component: <DropVaultConfigList />,
    exact: true,
    path: '/apps/vault/config/list',
  },
  {
    component: <DropVaultConfig />,
    exact: true,
    path: '/apps/vault/config/register/',
  },
  {
    component: <DropVaultConfig />,
    exact: true,
    path: '/apps/vault/config/register/:id',
  },
  {
    component: <VaultStylesList />,
    exact: true,
    path: '/apps/vault/style/list',
  },
  {
    component: <VaultStylesForm />,
    exact: true,
    path: '/apps/vault/style/register',
  },
  {
    component: <VaultStylesForm />,
    exact: true,
    path: '/apps/vault/style/:id',
  },
  {
    component: <WaitingList />,
    exact: true,
    path: '/apps/vault/waitingList/list',
  }
]

export default routes
