export interface DropVaultConfigPayload {
  lobbyDropChances: LobbyDropChancesProps;
  rankedsIds: number[];
  vaultLimitPerLobby: number;
}

export interface LobbyDropChancesProps {
  '0': number;
  '1': number;
  '2': number;
  '3': number;
}

export const vaultConfigInitialState: DropVaultConfigPayload = {
  lobbyDropChances: {
  '0': 0,
  '1': 0,
  '2': 0,
  '3': 0
  },
  rankedsIds: [],
  vaultLimitPerLobby: 0
}
