import { useEffect, useState } from 'react'

// SERVICES
import { getWaitingList, removeFromWaitingList } from '@services/Vault/VaultService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { LoadingButton } from '@mui/lab'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Card, Grid, TextField, Box, Button } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'

// ICONS
import { PersonSearch } from '@mui/icons-material'

// TYPES
import { GetWaitingList } from '@shared/types/VaultTypes'

// STYLES
import * as Styled from './styles'
import { purple } from '@mui/material/colors'

const WaitingList = (): JSX.Element => {
  const [waitingList, setWaitingList] = useState<GetWaitingList[]>()
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)



  const _getWaitingList = async (): Promise<void> => {
    try {
      setLoading(true)
      const waitingListData: GetWaitingList[] = await getWaitingList(searchText)
      setWaitingList(waitingListData)
    } finally {
      setLoading(false)
    }
  }

  const handleSearchSubmit = (): void => {
    _getWaitingList()
  }

  const renderItemImage = (itemImage): JSX.Element | null => {
    if (!itemImage.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Item"
        src={itemImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderVaultImage = (vaultImage): JSX.Element | null => {
    if (!vaultImage.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Cofre"
        src={vaultImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderDataGrid = (): JSX.Element => {
    if (!waitingList)
      return <p>Busque uma lista de espera usando o gcId do player acima</p>

    if (waitingList.length === 0)
      return <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />

    return (
      <DataGrid
        rows={waitingList}
        columns={columns}
        autoHeight
        rowHeight={80}
        disableSelectionOnClick
        sx={{
          ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
            outline: 'none',
          },
          ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
            whiteSpace: 'break-spaces',
          },
        }}
      />
    )
  }

  const _handleItemDeletion = async (vaultUserId: string, name: string , vaultName: string, playerId: string, waitingListId: string): Promise<void> => {
    Alert.remove(
      `Você está prestes a remover a Skin ${name} do usuário ${playerId}, assim como seu cofre ${vaultName}, e essa ação não pode ser desfeita. Deseja prosseguir?`
    )
      .then(async (result) => {
        if (result.isConfirmed) {
          const payload = {
            vaultUserId,
            name,
            vaultName,
            playerId,
            waitingListId
          }
          await removeFromWaitingList(payload)
          Alert.success('Usuário removido da lista de espera com sucesso.')
          await _getWaitingList()
        }
      })
      .catch((e) => {
        Alert.error('Não foi possível remover o usuário da lista de espera')
      })
  }


  const renderActionButtons = ( id: string, item: string , vault: string, playerId: string, waitingListId: string): JSX.Element => {
    return (
      <Button
        onClick={(): Promise<void> => _handleItemDeletion(id, item, vault, playerId, waitingListId)}
        color="error"
        variant="contained"
      >
        Remover
      </Button>
    )
  }

  const columns: GridColumns = [
    { field: 'waitingListId', hide: true },
    {
      field: 'id',
      headerName: 'Id',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'item',
      headerName: 'Nome da skin',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
    },
    {
      field: 'vault',
      headerName: 'Nome do vault',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 125,
    },
    {
      field: 'vaultImage',
      headerName: 'Imagem do vault',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 145,
      renderCell: (params) => renderItemImage(params.row.vaultImage),
    },
    {
      field: 'image',
      headerName: 'Imagem da skin',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => renderVaultImage(params.row.image),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) =>
        renderActionButtons(
          params.row.id,
          params.row.item,
          params.row.vault,
          params.row.playerId,
          params.row.waitingListId
        ),
    }
  ]

  useEffect(() => {
    searchText && handleSearchSubmit()
  }, [])

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de espera">
        <AssignmentRoundedIcon fontSize="large" style={{ fill: purple[500] }} />
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="Buscar"
          variant="outlined"
          onChange={(e) => setSearchText(e.target.value)}
          disabled={loading}
          placeholder="GC ID do player"
          value={searchText}
        />
        <LoadingButton
          variant="contained"
          onClick={(): void => handleSearchSubmit()}
          disabled={loading || !searchText}
          loading={loading}
        >
          <PersonSearch /> {!searchText ? 'Digite um GC ID' : 'Buscar'}
        </LoadingButton>
      </Styled.SearchForm>
      {renderDataGrid()}
    </Styled.Container>
  )
}

export { WaitingList }
