// API
import { vaultApi } from '@shared/api/Vault/VaultApi'
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import { 
  CreateItemPayload,
  UpdateItemPayload,
  GetAllItemResponse,
  ItemResponse
} from '@shared/types/ItemsTypes'
import { Product, GetProductsResponse } from '@shared/types/ProductTypes'

const getAllItems = (): Promise<GetAllItemResponse> => {
  return vaultApi.get(`/api/item/`)
}

const createItem = (data: CreateItemPayload): Promise<ItemResponse> => {
  return vaultApi.post('/api/item/', data)
}

const updateItem = (data: UpdateItemPayload): Promise<ItemResponse> => {
  return vaultApi.put(`/api/item/${data.id}`, data)
}

const getItems = (id: string): Promise<ItemResponse> => {
  return vaultApi.get(`/api/item/${id}`)
}

const getProducts = (data?): Promise<GetProductsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}

const getProduct = (id: string): Promise<Product> => {
  return marketplaceApi.get(`/backoffice/products/${id}`)
}

export { 
  createItem,
  updateItem,
  getItems,
  getAllItems,
  getProducts,
  getProduct
}
