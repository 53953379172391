import axios from 'axios'

// ENV
import { HOST_URL } from '@config/environment'

// TYPES
import {
  PlayerInfoByTwitch,
} from '@shared/types/VaultTypes'

const getPlayersInfoByTwitchIds = async (twitchIds: string[]): Promise<PlayerInfoByTwitch[]> => {
  const v1api = axios.create({
    baseURL: HOST_URL,
  })

  const data = twitchIds.map(v => ({twitchId: v}))
  const response = await v1api.post(`/api/twitch/players`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic YnJvYWRjYXN0OmJyb2FkY2FzdGdhbWVyc2NsdWJldmVudHM=',
    }
  })

  const playersInfo = response.data.data

  playersInfo.sort((p1, p2) => twitchIds.indexOf(p1.twitchId) - twitchIds.indexOf(p2.twitchId));

  return playersInfo
  /* return {
    data: [
      {
          plSteamID64: "76561198069569054",
          accountId: "ddc2a2fd-849b-4c65-9abf-183c232b0874",
          player_id: "126340",
          twitchId: "103926950"
      },
      {
          plSteamID64: "76561198027537910",
          accountId: "c64f1898-9f8c-4407-ab70-02b210b03703",
          player_id: "28712",
          twitchId: "87022616"
      }
    ]
  } */
}

export {
  getPlayersInfoByTwitchIds,
}
