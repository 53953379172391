import { useState } from 'react'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from '@mui/material'

import {
  ExpandLess,
  ExpandMore,
  AssignmentRounded as AssignmentRoundedIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalShipping as LocalShippingIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
  FormatColorFill as StyleIcon,
  Inbox,
  Storefront,
  Inventory,
  Add,
  EmojiEvents,
  LiveTv,
  AppsOutage,
  FolderShared,
} from '@mui/icons-material'

// STYLES
import * as Styled from './styles'
import { purple } from '@mui/material/colors'

const SideBar = (): JSX.Element => {
  const drawerWidth = 250

  const menuItemsWithSubItem = [
    {
      id: 1,
      name: 'Cofre',
      icon: <Inbox />,
      subItems: [
        {
          name: 'Listar cofres',
          link: '/apps/vault/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/vault/register',
          icon: <ShoppingCartIcon />,
        },
        {
          name: 'Ultimos Drops',
          link: '/apps/vault/last-drops',
          icon: <PersonIcon />,
        },
      ],
    },
    {
      id: 2,
      name: 'Itens de cofres',
      icon: <Inventory />,
      subItems: [
        {
          name: 'Gerenciar',
          link: '/apps/vault/items/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar do marketplace',
          link: '/apps/vault/items/marketplace/register',
          icon: <Add />,
        },
        {
          name: 'Cadastrar coins',
          link: '/apps/vault/items/coins/register',
          icon: <Add />,
        },
      ],
    },
    {
      id: 3,
      name: 'Estilos de cofres',
      icon: <StyleIcon />,
      subItems: [
        {
          name: 'Listar',
          link: '/apps/vault/style/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar estilo',
          link: '/apps/vault/style/register',
          icon: <Add />,
        },
      ],
    },
    {
      id: 4,
      name: 'Jogadores',
      icon: <Storefront />,
      subItems: [
        {
          name: 'Lista de trades',
          link: '/apps/vault/players/trade/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Entregar um cofre',
          link: '/apps/vault/delivery',
          icon: <LocalShippingIcon />,
        },
      ],
    },
    {
      id: 5,
      name: 'Masters',
      icon: <EmojiEvents />,
      subItems: [
        {
          name: 'Entregar cofres por Twitch',
          link: '/apps/vault/delivery/twitch',
          icon: <LiveTv />,
        },
      ],
    },
    {
      id: 6,
      name: 'Lista de Espera',
      icon: <AppsOutage />,
      subItems: [
        {
          name: 'Lista de espera Player',
          link: '/apps/vault/waitingList/list',
          icon: <FolderShared />,
        },
      ],
    },
    {
      id: 7,
      name: 'Configurações',
      icon: <SettingsIcon />,
      subItems: [
        {
          name: 'Listar',
          link: '/apps/vault/config/list',
          icon: <SettingsIcon />,
        },
        {
          name: 'Cadastrar configuração',
          link: '/apps/vault/config/register',
          icon: <Add />,
        },
      ],
    }
  ]

  const menuItems = [
    // {
    //   id: 1,
    //   name: 'Exemplo',
    //   link: '/apps/vault/exemplo',
    //   icon: <SettingsIcon />,
    // },
  ]

  const [isMenuVisible, setIsMenuVisible] = useState(
    menuItemsWithSubItem.reduce(
      (menuItem, { id }) => ({
        ...menuItem,
        [id]: false,
      }),
      {}
    )
  )

  const handleClick = (id: number): void => {
    setIsMenuVisible({
      ...isMenuVisible,
      [id]: !isMenuVisible[id],
    })
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: purple[700],
        },
      }}
    >
      <Toolbar sx={{ mt: 2 }} />
      {menuItemsWithSubItem.map((item, itemsIndex) => (
        <List component="nav" key={itemsIndex} disablePadding>
          <ListItemButton onClick={(): void => handleClick(item.id)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.name}
              disableTypography
              sx={{ fontWeight: '300' }}
            />
            {isMenuVisible[item.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isMenuVisible[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem, subItemIndex) => (
                <Styled.LinkMenu key={subItemIndex} to={subItem.link}>
                  <ListItem button key={subItem.name} sx={{ pl: 4 }}>
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={subItem.name}
                      disableTypography
                      sx={{ fontWeight: '300' }}
                    />
                  </ListItem>
                </Styled.LinkMenu>
              ))}
            </List>
          </Collapse>
        </List>
      ))}

      <List component="nav" disablePadding>
        <List component="div" disablePadding>
          {menuItems.map((item) => (
            <Styled.LinkMenu key={item.id} to={item.link}>
              <ListItem button sx={{ pl: 2 }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.name}
                  disableTypography
                  sx={{ fontWeight: '300' }}
                />
              </ListItem>
            </Styled.LinkMenu>
          ))}
        </List>
      </List>

      <Styled.FooterContainer>
        <Styled.Divider />
        <Styled.Footer>
          &copy; {new Date().getFullYear()}{' '}
          <Styled.FooterLink href="http://gamersclub.com.br" target="_blank">
            GamersClub
          </Styled.FooterLink>{' '}
          made with ❤️ for a better backoffice
        </Styled.Footer>
      </Styled.FooterContainer>
    </Drawer>
  )
}

export { SideBar }
