import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import { 
  getProducts, 
  getVaultType, 
  getVaultStyles,
  getCosmetics, 
  getVaultInfo,
  getRankeds,
  createVault,
  deleteVault,
  updateVault
} from '@services/Vault/VaultService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Autocomplete,
  TextField,
  Card,
  FormControl,
  Switch,
  FormControlLabel,
  Button,
  Box,
  Stack,
  Divider,
  OutlinedInput,
  MenuItem,
  Select,
  InputLabel,
  Typography,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { VaultTiers } from '@components/Vault/components/VaultTiers/VaultTiers'
import { VaultItems } from '@components/Vault/components/VaultItems/VaultItems'
import { UpdateSkinsButton } from '@components/Vault/components/UpdateSkinsButton/UpdateSkinsButton'
import { ProductCard } from '@components/shared/ProductCard/ProductCard'

// TYPES
import { Product } from '@shared/types/ProductTypes'
import {
  VaultType,
  VaultStyle,
  CosmeticsItems,
  VaultTier,
  FetchSkins,
  VaultItem
} from '@shared/types/VaultTypes'
import { Ranked } from '@shared/types/RankedTypes'

// STYLES
import { ImageUpload } from '@shared/components/ImageUpload/ImageUpload'

const VaultForm = (): JSX.Element => {
  const [productsItems, setProductsItems] = useState<Product[]>([])
  const [cosmeticsItems, setCosmeticsItems] = useState<CosmeticsItems[]>([])
  const [vaultType, setVaultType] = useState<VaultType[]>([])
  const [vaultStyles, setVaultStyles] = useState<VaultStyle[]>([])
  const [rankeds, setRankeds] = useState<Ranked[]>([])

  const [name, setName] = useState<string>('')
  const [slug, setSlug] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [type, setType] = useState<number>(null)
  const [style, setStyle] = useState<number>(null)
  const [dropRate, setDropRate] = useState<string>('')
  const [expiresIn, setExpiresIn] = useState<string>('')
  const [keySlug, setKeySlug] = useState<string>('')
  const [active, setActive] = useState<boolean>(false)
  const [buyable, setBuyable] = useState<boolean>(false)
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null)
  const [checkStock, setCheckStock] = useState<boolean>(false)
  const [selectedRankeds, setSelectedRankeds] = useState<number[]>([])

  const [selectedCosmetics, setSelectedCosmetics] = useState<CosmeticsItems[]>([])
  const [structuredCosmetics, setStructuredCosmetics] = useState([])
  const [selectedSkins, setSelectedSkins] = useState<FetchSkins[]>([])

  const [vaultTiers, setVaultTiers] = useState<VaultTier[]>([{ tier: 0, dropRate: 0 }])
  const [vaultBots, setVaultBots] = useState<number[]>([])
  const [vaultItems, setVaultItems] = useState<VaultItem[]>([])

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => setProductsItems(response.results))
    getVaultType().then((response) => setVaultType(response.vaultType))
    getVaultStyles().then((response) => setVaultStyles(response))
    getCosmetics({ page: NaN }).then((response) => setCosmeticsItems(response.items))
    getRankeds().then((response) => setRankeds(response))

    if (id) {
      _getVaultInfo()
    }

  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updateVault() 
    } else {
      _createVault()
    }
  }

  const createPayload = () => {
    return {
      ...(id && {id: id}),
      name,
      slug,
      keySlug,
      description,
      image,
      active,
      dropRate: Number(dropRate),
      vaultTiers,
      expiresIn: !expiresIn ? null : expiresIn,
      buyable,
      backgroundImage,
      items: vaultItems,
      vaultTypeId: type || 1,
      vaultStyleId: style || 1,
      vaultBots,
      checkStock,
      rankedsIds: selectedRankeds
    }
  }

  const _createVault = async (): Promise<void> => {
    try {
      const payload = createPayload()
      await createVault(payload)

      Alert.success(
        'Vault criado com sucesso',
        navigate('/apps/vault/list') 
      )
    } catch {
      Alert.error('Ocorreu um erro criar o Vault')
    }
  }

  const _updateVault = async (): Promise<void> => {
    try {
      const payload = createPayload()
      await updateVault(payload)

      Alert.success(
        'Vault atualizado com sucesso',
        navigate('/apps/vault/list') 
      )
    } catch {
      Alert.error('Ocorreu um erro atualizar o Vault')
    }
  }

  const _getVaultInfo = async (): Promise<void> => {
    try {
      const vaultData = await getVaultInfo(id)

      const skins = vaultData.vault.vaultItems.filter(item => item.typeItem === 0)
      const cosmetics = vaultData.vault.vaultItems.filter(item => item.typeItem >= 1)
      const selectedRankeds = vaultData.vault.rankedsIds || []

      const structuredSkins = skins.reduce(
        (acumulatedValue, currentValue) => {
          const foundItem = acumulatedValue.find(item => item.slug === currentValue.slug)
          if (foundItem) {
            foundItem.stock += currentValue.stock
            return acumulatedValue
          }
          const newItem = {
            tier: currentValue.tier,
            stock: currentValue.stock,
            slug: currentValue.slug,
            id: currentValue.id,
            quantity: currentValue.quantity || 1,
            typeItem: currentValue.typeItem
          }
          return [...acumulatedValue, newItem]
        },
        []
      )

      const structuredCosmetics = []
      cosmetics.map(item => structuredCosmetics.push({
        name: item.name,
        tier: item.tier,
        stock: item.stock,
        slug: item.slug,
        id: item.id,
        quantity: item.quantity,
        typeItem: item.typeItem
      }))

      if (vaultData) {
        setName(vaultData.vault.name)
        setSlug(vaultData.vault.slug)  
        setDescription(vaultData.vault.description)
        setImage(vaultData.vault.image)
        setType(vaultData.vault.vaultTypeId)
        setStyle(vaultData.vault.vaultStyle?.id || 1)
        setDropRate(String(vaultData.vault.dropRate))
        setExpiresIn(vaultData.vault.expiresIn)
        setKeySlug(vaultData.vault.keySlug)
        setActive(vaultData.vault.active)
        setBuyable(vaultData.vault.buyable)
        setBackgroundImage(vaultData.vault.backgroundImage)
        setVaultTiers(vaultData.vault.vaultTiers)
        setVaultBots(vaultData.vault.vaultBots)
        setVaultItems(vaultData.vault.vaultItems)
        setCheckStock(vaultData.vault.checkStock)
        setSelectedSkins(structuredSkins)
        setSelectedCosmetics(structuredCosmetics)
        setStructuredCosmetics(structuredCosmetics)
        setSelectedRankeds(vaultData.vault.rankedsIds || [])
      }
    } catch(err) {
      Alert.error('Ocorreu um erro ao carregar o Cofre')
    }
  }

  const _deleteVault = async (id: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir esse cofre?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteVault(id)
          Alert.success(
            'Cofre de recompensas deletado com sucesso!',
            navigate('/apps/vault/list')
          )
        }
      })
      .catch((e) => {
        Alert.error('Erro ao delete o cofre de recompensas')
      })
  }

  const filteredProducts = productsItems.filter(product => product.category !== undefined && product.category.name === 'vault')
  const availableVaultItems = cosmeticsItems.sort(function (a, b) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  })

  const validateFields = (): string[] => {
    const errors = []
    // if (!playerId.trim().length) errors.push('O campo Player ID é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Cofre${id ? ` ${id}` : ''}`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const handleUploadVaultBanner = (rawFile, readerResult) => {
    setBackgroundImage(readerResult)
  }
  const renderVaultBanner = () => {
    return (
      <Grid item>
        <Typography textAlign="center">
          Imagem de fundo
        </Typography>
        <ImageUpload
          cropped={false}
          defaultImage={backgroundImage}
          restrictions=".jpg, .jpeg, .png"
          handleSubmit={(rawFile, readerResult) => {
            handleUploadVaultBanner(rawFile, readerResult)
          }}
        />
      </Grid>
    )
  }
  const handleProductsInfo = (product: Product) => {
    setName(product.name)
    setSlug(product.slug)
    setDescription(product.description)
    setImage(product.pictures[0].url)
  }

  const currentProductCard = () => {
    if(!name) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 1, width: '377px' }}>
          Sem produto selecionado
        </Grid>
      )
    }

    return (
      <ProductCard 
        imageUrl={image}
        name={name}
        slug={slug}
        description={description}
      />
    )
  }

  const handleSelectedTiers = (tiers) => {
    setVaultTiers(tiers)
  }

  const handleSelectedItems = (items) => {
    setVaultItems(items)
  }

  const handleVaultBots = (value) => {
    setVaultBots(value)
  }

  const handleSelectedSkins = (skins) => {
    const structured = []

    skins[0].map((item: VaultItem) => structured.push({
      tier: 0,
      id: item.id,
      slug: item.slug,
      stock: item.stock,
      quantity: 1,
      typeItem: 0
    }))

    setSelectedSkins(structured)
  }

  const handleVaultItemsSelection = (value) => {
    const selected = value

    const structured = []

    selected.map((item: CosmeticsItems) => {
      const currentItem = cosmeticsItems.filter(fullItem => fullItem.id === item.id)[0]

      return structured.push({
        tier: 0,
        slug: currentItem.slug,
        stock: currentItem.stock,
        quantity: currentItem.quantity,
        id: currentItem.id,
        typeItem: 1
      })
    })
    setSelectedCosmetics(selected)
    setStructuredCosmetics(structured)
  }

  const renderDropdownOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const handleAlert = (type, vaultId?) => {
    if (type === 'SUCCESS') {
      Alert.success(
        'Skins atualizadas com sucesso!',
        navigate(`/apps/vault/${vaultId}`)
      )
    }
    if (type === 'FAILURE') {
      Alert.error('Erro ao tentar atualizar skins')
    }
  }

  return (
    <Card >
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
          <Box>
            <Grid 
              container 
              spacing={2} 
              sx={{ mb: 2 }}  
            >
              <Grid item xs={12}>
                <Grid container spacing={3} alignItems="center" justifyContent="start">
                  {!id &&
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <Autocomplete
                          onChange={(event: any, newValue: Product) => {
                            handleProductsInfo(newValue)
                          }}
                          options={filteredProducts}
                          getOptionLabel={(option: Product) => `${option.name} (${option.slug})`}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} label="Produtos" />
                            )}
                        />
                      </FormControl>
                    </Grid>
                  }
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                    <InputLabel shrink>Tipo de Cofre</InputLabel>
                      <Select
                        required
                        fullWidth={true}
                        value={type}
                        onChange={(e) => setType(Number(e.target.value))}
                        input={<OutlinedInput notched label="Tipo de Cofre" />}
                      >
                        {renderDropdownOptions(vaultType)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                    <InputLabel shrink>Estilo do Cofre</InputLabel>
                      <Select
                        required
                        fullWidth={true}
                        value={style}
                        onChange={(e) => setStyle(Number(e.target.value))}
                        input={<OutlinedInput notched label="Estilo do Cofre" />}
                      >
                        {renderDropdownOptions(vaultStyles)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Dias para expiração"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setExpiresIn(e.target.value)}
                      value={expiresIn}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      required
                      label="Chances de drop (0 a 1)"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={(e) => {
                        if (Number(e.target.value) >= 0 && Number(e.target.value) <= 1) {
                          setDropRate(e.target.value)
                        }
                      }}
                      value={dropRate}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={active}
                          size="small"
                          onChange={(e) => setActive(e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label={`Cofre ${active ? 'ativo' : 'inativo'}`}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={buyable}
                          size="small"
                          onChange={(e) => setBuyable(e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label='Pode ser vendido'
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkStock}
                          size="small"
                          onChange={(e) => setCheckStock(e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label='Checar estoque'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {
                    keySlug
                      ? (<TextField
                        disabled
                        label="Slug da senha de abertura"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setKeySlug(e.target.value)}
                        value={keySlug}
                      />)
                      : (<>&nbsp;</>)
                    }
                  </Grid>
                {id &&
                  <Grid item xs={3}>
                    <UpdateSkinsButton
                      handleAlert={handleAlert}
                      vaultId={id}
                    />
                    <Typography variant="caption" display="block" gutterBottom>
                      IDs dos bots: {vaultBots.toString()}
                    </Typography>
                  </Grid>
                }
                  <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Rankeds vinculadas</InputLabel>
                      <Select
                        multiple
                        fullWidth={true}
                        value={selectedRankeds}
                        onChange={(e) => setSelectedRankeds(e.target.value as number[])}
                        input={<OutlinedInput notched label="Rankeds vinculadas" />}
                      >
                        {renderDropdownOptions(rankeds)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <VaultTiers 
                  setSelectedTiers={(tiers) => handleSelectedTiers(tiers)}
                  vaultTiers={vaultTiers}
                />
              </Grid>
              {id && active
              ? <></>
              : <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      value={selectedCosmetics as CosmeticsItems[]}
                      onChange={(e, newValue: CosmeticsItems[]) => {
                        handleVaultItemsSelection(newValue)
                      }}
                      options={availableVaultItems}
                      getOptionLabel={(option: CosmeticsItems) => `${option.name} (quant: ${option.quantity})`}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField {...params} label="Selecione os itens digitais" />
                      )}
                    />
                  </FormControl>
                </Grid>
              }
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <VaultItems
                  skins={selectedSkins}
                  cosmetics={structuredCosmetics}
                  tiers={vaultTiers}
                  setSelectedItems={(items) => handleSelectedItems(items)}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ ml: 2, width: '377px'}} alignItems="center" justifyContent="center">
          <Grid container direction="column" alignItems="center" spacing="20px" sx={{ height: '100%' }}>
            {currentProductCard()}
            <hr style={{ width: '80%' }} />
            {renderVaultBanner()}
          </Grid>
          </Box>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs sx={{ mt: 5 }}>
              <Box
                display="flex"
                justifyContent='flex-end'
                >
                <Button variant="contained" type="submit">
                  {buttonLabelAction} Cofre
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { VaultForm }
