// API
import { vaultApi } from '@shared/api/Vault/VaultApi'

// TYPES
import { VaultConfig } from '@shared/types/VaultTypes'

export const getConfig = <
  T extends string | unknown,
  R = T extends string ? VaultConfig : VaultConfig[]
>(id?: T): Promise<R> => {
  return vaultApi.get(`/api/config/${id ?? ''}`)
}

export const createConfig = (data: VaultConfig): Promise<VaultConfig> => {
  return vaultApi.post('/api/config', data)
}

export const updateConfig = (data: VaultConfig, id: string): Promise<VaultConfig> => {
  return vaultApi.put(`/api/config/${id}`, data)
}

export const removeConfig = (id: string | number): Promise<void> => {
  if (!id) return
  return vaultApi.delete(`/api/config/${id}`)
}
