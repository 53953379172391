import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import { getConfig, createConfig, updateConfig } from '@services/Vault/ConfigService'
import { getRankeds } from '@services/Vault/VaultService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// TYPES
import { DropVaultConfigPayload, vaultConfigInitialState } from './types'
import { Ranked } from '@shared/types/RankedTypes'

// ICONS
import { ReportOutlined as ReportIcon } from '@mui/icons-material'

const DropVaultConfig = (): JSX.Element => {
  const [rankeds, setRankeds] = useState<Ranked[]>([])
  const [vaultConfig, setVaultConfig] = useState<DropVaultConfigPayload>(
    vaultConfigInitialState
    )
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (id) _getConfig()
    _getRankeds()
  }, [])

  const _getConfig = async (): Promise<void> => {
    const vaultLimitInfo = await getConfig(id)
    const { lobbyDropChances, rankedsIds, vaultLimitPerLobby } = vaultLimitInfo
    setVaultConfig({
      lobbyDropChances: {
        '0': lobbyDropChances[0],
        '1': lobbyDropChances[1],
        '2': lobbyDropChances[2],
        '3': lobbyDropChances[3],
      },
      rankedsIds,
      vaultLimitPerLobby,
    })
  }

  const _getRankeds = async (): Promise<void> => {
    const rankedsData = await getRankeds()
    setRankeds(rankedsData)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updateConfig()
    } else {
      _createConfig()
    }
  }

  const _createConfig = async (): Promise<void> => {
    try {
      await createConfig(vaultConfig)
      Alert.success(
        'Configuração criada com sucesso',
        navigate('/apps/vault/config/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar o cadastro')
    }
  }

  const _updateConfig = async (): Promise<void> => {
    try {
      await updateConfig(vaultConfig, id)
      Alert.success(
        'Alteração realizada com sucesso',
        navigate('/apps/vault/config/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a alteração')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    const {
      lobbyDropChances,
      vaultLimitPerLobby
    } = vaultConfig

    if (vaultLimitPerLobby < 0)
      errors.push(`O campo "Limite de cofres por lobby" é obrigatório`)
    if (lobbyDropChances[0] < 0)
      errors.push(`O campo "Chances de NÃO dropar cofres" é obrigatório`)
    if (lobbyDropChances[1] < 0)
      errors.push(`O campo "Chances de dropar UM cofre" é obrigatório`)
    if (lobbyDropChances[2] < 0)
      errors.push(`O campo "Chances de dropar DOIS cofres" é obrigatório`)
    if (lobbyDropChances[3] < 0)
      errors.push(`O campo "Chances de dropar TRÊS cofres" é obrigatório`)
    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Editar Configurações">
        <ReportIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const updateChances = (index: number, value: number): void => {
    if (value < 0) return
    setVaultConfig(oldConfig => {
      return {
        ...oldConfig,
        lobbyDropChances: {
          ...oldConfig.lobbyDropChances,
          [index]: value,
        },
      }
    })
  }

  const sumChances = (): number => {
    const chancesArray: number[] = Object.values(vaultConfig.lobbyDropChances)
    return chancesArray.reduce(
      (previousValue: number, currentValue: number): number => {
        return previousValue + currentValue || 0
      },
      0
    )
  }

  const renderDropdownOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel shrink>Rankeds vinculadas</InputLabel>
                <Select
                  multiple
                  fullWidth={true}
                  value={vaultConfig.rankedsIds || []}
                  onChange={(e) => setVaultConfig(oldConfig => {
                    return {
                      ...oldConfig,
                      rankedsIds: e.target.value as number[]
                    }
                  })}
                  input={<OutlinedInput notched label="Rankeds vinculadas" />}
                >
                  {renderDropdownOptions(rankeds)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Limites de cofres por lobby"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  if (parseInt(e.target.value) < 0) return
                  setVaultConfig(oldConfig => {
                    return {
                      ...oldConfig,
                      vaultLimitPerLobby: parseInt(e.target.value)
                    }
                  })
                }}
                placeholder="Apenas numeros"
                value={vaultConfig.vaultLimitPerLobby}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Chances de NÃO dropar cofres"
                variant="outlined"
                fullWidth
                onChange={(e) => updateChances(0, parseInt(e.target.value))}
                placeholder="Apenas numeros"
                value={vaultConfig.lobbyDropChances[0] || 0}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Chances de dropar UM cofre"
                variant="outlined"
                fullWidth
                onChange={(e) => updateChances(1, parseInt(e.target.value))}
                placeholder="Apenas numeros"
                value={vaultConfig.lobbyDropChances[1] || 0}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Chances de dropar DOIS cofres"
                variant="outlined"
                fullWidth
                onChange={(e) => updateChances(2, parseInt(e.target.value))}
                placeholder="Apenas numeros"
                value={vaultConfig.lobbyDropChances[2] || 0}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Chances de dropar TRÊS cofres"
                variant="outlined"
                fullWidth
                onChange={(e) => updateChances(3, parseInt(e.target.value))}
                placeholder="Apenas numeros"
                value={vaultConfig.lobbyDropChances[3] || 0}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography color="red">
                {sumChances() !== 100 && 'O percentual de drops deve somar 100'}
              </Typography>
            </Grid>

            <Grid item xs={2} sx={{ mt: 2 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button
                  disabled={sumChances() !== 100}
                  variant="contained"
                  type="submit"
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { DropVaultConfig }
