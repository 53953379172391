// API
import { vaultApi } from '@shared/api/Vault/VaultApi'

// TYPES
import { GetDropsResponse } from '@components/LastDropsList/types'

const getDrops = (id: string): Promise<GetDropsResponse> => {
  return vaultApi.get(`/api/user?playerId=${id}`)
}

const getAllPlayers = (data?): Promise<GetDropsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return vaultApi.get(`/api/user${queryString}`)
}

export { getDrops, getAllPlayers }
