import { useState } from 'react'

// SERVICES
import { getPlayersInfoByTwitchIds } from '@services/Vault/DeliveryService'
import { deliveryVault } from '@services/Vault/VaultService'

// TYPES
import {
  PlayerInfoByTwitch
} from '@shared/types/VaultTypes'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField
} from '@mui/material'

import { LiveTv as LiveTvIconIcon } from '@mui/icons-material'

import { Loader } from '@components/Loader/Loader'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const MAX_VALUES_COUNT = 100

const VAULTS = [
  { slug: 'cofre-premium-transmissao-gc-masters-fem-vi-i', name: 'Cofre Premium - Transmissão GC Masters Fem VI (09/12)' },
  { slug: 'cofre-premium-gc-masters-fem-vi-ii', name: 'Cofre Premium - Transmissão GC Masters Fem VI (10/12)' },
  { slug: 'cofre-premium-gc-masters-fem-vi-iii', name: 'Cofre Premium - Transmissão GC Masters Fem VI (11/12)' },
  { slug: 'cofre-plus-transmissao-gc-masters-fem-iv', name: 'Cofre Plus - Transmissão GC Masters Fem VI' },
]

const VaultDeliveryToTwitchForm = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  
  const [csvContent, setCsvContent] = useState('')
  const [slug, setSlug] = useState<string>(VAULTS[0].slug)
  const [quantity, setQuantity] = useState(20)
  
  const [searchErrors, setSearchErrors] = useState<string[]>([])
  const [playersInfo, setPlayersInfo] = useState<PlayerInfoByTwitch[]>([])

  const _sendVaults = async (values: string[]): Promise<void> => {
    try {
      setIsLoading(true)

      const queryValues = values.slice(0, MAX_VALUES_COUNT)
      const playersInfoResponse = await getPlayersInfoByTwitchIds(queryValues)

      const playersInfo = playersInfoResponse.slice(0, quantity)
      const playerIds = playersInfo.map(player => player.player_id)

      const errors = []

      if(!playersInfo.length) {
        errors.push('Nenhum player foi encontrado')
      }

      await deliveryVault({
        slug,
        playerIds,
        notUseItemKey: true,
        reference: 'TWITCH'
      })

      if(values.length > MAX_VALUES_COUNT) {
        errors.push(`Número máximo de valores para a busca é ${MAX_VALUES_COUNT}, os valores excedentes foram descartados.`)
      }

      if(values.length !== new Set(values).size) {
        errors.push('Existem valores duplicados.')
      }

      if(queryValues.length !== playersInfoResponse.length) {
        const notFoundPlayers = queryValues.filter(twitchId => !playersInfoResponse.some(player => player.twitchId === twitchId))
        errors.push('Um ou mais players não foram encontrados: ', notFoundPlayers.join(', '))
      }

      setPlayersInfo(playersInfo)
      setSearchErrors(errors)
      Alert.success('Cofres entregues com sucesso')
    } catch(error) {
      console.log(error)
      Alert.error(error?.errors?.join('.\n') || error.message || 'Houve um erro ao executar ação')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const values = csvContent
      .replace(/[\r'" ]/g, '')
      .replace(/(,+|;+|\s+)/g, '\n')
      .replace(/(\n+)/g, '\n')
      .replace(/^\n|\n$/g, '')
      .split(/[\n]/g)

    _sendVaults(values)
  }

  const getResult = (): string => {
    const headers = [
      'twitchId',
      'playerId',
    ]
    const fields = [
      'twitchId',
      'player_id',
    ]

    const players = playersInfo
      .map((player) => fields.map((field) => (player[field] || '')).join(', '))
      .join('\n')
    
    return `${headers.join(', ')}\n${players}`
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Entregar cofres por Twitch">
        <LiveTvIconIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const renderLoading = (): JSX.Element => {
    return isLoading 
      ? <Loader type="overlay" />
      : <></>
  }

  const renderErrors = (): JSX.Element => {
    if(!searchErrors.length) {
      return <></>
    }

    return (
      <div>
        { searchErrors.map((error, index) => (<Styled.Error key={`error_${index}`}>- {error}</Styled.Error>)) }
      </div>
    )
  }

  const renderVaultOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.slug} value={obj.slug}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const renderResult = (): JSX.Element => {
    if(!playersInfo.length) {
      return <></>
    }

    return (
      <Grid container spacing={2} sx={{ mt: 0.2 }}>
        <Grid item xs={12}>
          <TextField
            key="playersInfo"
            label="Cofre entregue para:"
            placeholder="Players"
            multiline={true}
            maxRows={10}
            style={{ width: '100%' }}
            value={getResult()}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Card style={{ minWidth: '80%' }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent>
        <form onSubmit={handleSearchSubmit}>
          <Grid container
                spacing={2}
                direction="column"
                sx={{ mt: 0.2, mb: 3 }}>
            <Grid item xs={12}>
              <TextField
                key="csvContent"
                label="Twitch Ids"
                placeholder={'valor 1\nvalor 2\nvalor 3\n...'}
                multiline={true}
                minRows={5}
                maxRows={10}
                style={{ width: 350 }}
                onChange={(e) => setCsvContent(e.target.value)}
                value={csvContent}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="vault-slug-name-label">Cofre</InputLabel>
                <Select
                  label="Cofre"
                  labelId="vault"
                  id="vault"
                  value={slug}
                  style={{ width: 350 }}
                  onChange={(e) => setSlug(e.target.value)}
                >
                  {renderVaultOptions(VAULTS)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Quantidade Limite (Máx. 100)"
                variant="outlined"
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 1, max: 100 } }}
                style={{ width: 200 }}
                onChange={(e) => setQuantity(Number(e.target.value))}
                value={quantity}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Enviar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>

        { renderLoading() }
        { renderErrors() }
        { renderResult() }
      </CardContent>
    </Card>
  )
}

export { VaultDeliveryToTwitchForm }
