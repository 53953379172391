import React from 'react'


// TYPES
import { ProductCardProps } from './types'

// STYLES
import * as Styled from './ProductCard.styles'

const ProductCard = ({ imageUrl, name, slug, description }: ProductCardProps): JSX.Element => {
  return (
    <Styled.Container>
      <Styled.ImageContainer>
        <Styled.Image
          src={imageUrl}
          alt={name}
          />
      </Styled.ImageContainer>
      <Styled.Name>
        {name}
      </Styled.Name>
      <Styled.Slug>
        {slug}
      </Styled.Slug>
      <Styled.Description
        dangerouslySetInnerHTML={{
          __html: description
        }}>
      </Styled.Description>
    </Styled.Container>
  )
}

export { ProductCard }
