import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// SERVICES
import { 
  createItem,
  getItems,
  getProducts,
  getProduct,
  updateItem
} from '@services/Items/ItemsService'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
  Divider,
  Stack,
  Autocomplete,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { ProductCard } from '@components/shared/ProductCard/ProductCard'

//TYPES
import { Product } from '@shared/types/ProductTypes'
import { ItemTypeEnum } from '@shared/types/ItemsTypes'

const MarketplaceItemsForm = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([])
  const [productId, setProductId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [stock, setStock] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(1);
  const [slug, setSlug] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [typeItem, setTypeItem] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => setProducts(response.results))
  }, [])

  useEffect(() => {
    if (products.length) {
      setLoading(false)
    }
  }, [products])

  useEffect(() => {
    if (id) {
      _getMarketplaceItems()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setLoading(true)

    if (id) {
      _updateMarketplaceItems() 
    } else {
      _createMarketplaceItems()
    }
  }

  const _createMarketplaceItems = async (): Promise<void> => {
    try {
      const payload = {
        name,
        description,
        stock,
        quantity,
        image,
        slug: slug,
        tier: null,
        typeItem,
      }

      await createItem(payload)

      Alert.success(
        'Item de cofre criado com sucesso!',
        navigate('/apps/vault/items/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao criar o item de cofre')
    }

    setLoading(false)
  }

  const _updateMarketplaceItems = async (): Promise<void> => {
    try {
      const payload = {
        id,
        name,
        description,
        stock,
        quantity,
        image,
        slug: slug,
        tier: null,
        typeItem,
      }

      await updateItem(payload)

      Alert.success(
        'Item de cofre editado com sucesso!',
        navigate('/apps/vault/items/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao editar o item de cofre')
    }

    setLoading(false)
  }

  const _getMarketplaceItems = async (): Promise<void> => {
    try {
      const itemData = await getItems(id)

      if (itemData) {
        setName(itemData.item.name)
        setDescription(itemData.item.description)
        setStock(itemData.item.stock)
        setQuantity(itemData.item.quantity)
        setSlug(itemData.item.slug)
        setImage(itemData.item.image)
        setTypeItem(itemData.item.typeItem)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar item de cofre')
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Produto`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const currentProductCard = () => {
    if(!name) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 1, width: '377px' }}>
          Sem produto selecionado
        </Grid>
      )
    }

    return (
      <ProductCard 
        imageUrl={image}
        name={name}
        slug={slug}
        description={description}
      />
    )
  }

  const handleSelectCurrentProduct = async (id): Promise<void> => {
    const product = await getProduct(id)

    setProductId(product.id),
    setName(product.name)
    setSlug(product.slug)
    setDescription(product.description)
    setImage(product.pictures[0].url)
    setTypeItem(ItemTypeEnum.DIGITAL)
  }

  const handleQuantity = (e) => {
    const qtd = e.target.value

    if (qtd <= 1) {
      setQuantity(1)
    } else {
      setQuantity(e.target.value)
    }
  }

  return (
    <Card sx={{ width: 800 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{p: 4}}>
        <form onSubmit={handleSubmit}> 
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <Box>
              <Grid container spacing={2}>
                {!id &&
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        onChange={(event: any, newValue: Product) => {
                          if (newValue.id) {
                            handleSelectCurrentProduct(newValue.id)
                          }
                        }}
                        loading={loading}
                        disabled={loading}
                        options={products}
                        getOptionLabel={(option: Product) => `${option.name} (${option.id})`}
                        renderInput={(params) => (
                          <TextField {...params} label="Selecione o produto" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    label="Quantidade"
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    placeholder="Apenas numeros"
                    fullWidth
                    onChange={(e) => handleQuantity(e)}
                    value={quantity}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Estoque"
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    placeholder="Apenas numeros"
                    fullWidth
                    onChange={(e) => setStock(Number(e.target.value))}
                    value={stock}
                    disabled={loading}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ ml: 2, width: '377px'}} alignItems="center" justifyContent="center">
              <Grid container direction="column" alignItems="center" justifyContent="space-between" sx={{ height: '100%' }}>
                {currentProductCard()}
              </Grid>
            </Box>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs sx={{ mt: 5 }}>
              <Box
                display="flex"
                justifyContent={`${id ? 'space-between' : 'flex-end'}`}
                >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}>
                  {buttonLabelAction} Produto
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { MarketplaceItemsForm }
