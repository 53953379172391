import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import { getVaults, getVaultType } from '@services/Vault/VaultService'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Chip,
  Button,
  LinearProgress,
  Card,
  styled,
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'
import { NotFound } from '@components/shared/NotFound/NotFound'

// TYPES
import { Vaults, VaultsResponse, VaultType } from '@shared/types/VaultTypes'

// ICONS
import { ReportOutlined as ReportIcon } from '@mui/icons-material'

// STYLES
import * as Styled from './VaultList.styles'

const VaultList = (): JSX.Element => {
  const [vaults, setVaults] = useState<Vaults[]>([])
  const [vaultType, setVaultType] = useState<VaultType[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getVaultType().then((response) => setVaultType(response.vaultType))
    _getVaults()
  }, [])

  const _getVaults = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const vaultsData: VaultsResponse = await getVaults()
      setVaults(vaultsData.vaults)
      setRowCount(vaultsData.vaults.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Listagem de cofres">
        <ReportIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <Button
        onClick={(): void => navigate(`/apps/vault/${id}`)}
        color="primary"
        variant="contained"
      >
        Editar
      </Button>
    )
  }

  const renderVaultSlug = (slug: string): JSX.Element => {
    return <Chip label={slug} color="default" />
  }

  const CustomChip = styled(Chip)(({ theme }) => ({
    color: 'white',
  }))

  const renderActiveVault = (active: boolean): JSX.Element => {
    return (
      <CustomChip
        label={active ? 'Ativo' : 'Inativo'}
        variant="filled"
        color={active ? 'success' : 'error'}
      />
    )
  }

  const renderVaultType = (vaultTypeId: number): JSX.Element => {
    const vaultName = vaultType.find(vault => vault.id === vaultTypeId)

    if(vaultTypeId === 1 || vaultTypeId === 2) {
      return (
        <CustomChip
          label={vaultName?.name}
          color={vaultTypeId === 1 ? 'primary' : 'warning'}
        />
      )
    }

    return (
      <CustomChip
        label={vaultName?.name}
        color='default'
      />
    )
  }

  const renderExpireDays = (expiresIn: string): JSX.Element => {
    if (expiresIn === null) {
      return <span>Não expira</span>
    }
    return <span>{expiresIn}</span>
  }

  const columns: GridColumns = [
    {
      field: 'vaultTypeId',
      headerName: 'Tipo',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => renderVaultType(params.row.vaultTypeId),
    },
    {
      field: 'name',
      headerName: 'Nome',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 220,
    },
    {
      field: 'dropRate',
      headerName: 'Drop rate',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: (params) => <span>{params.row.dropRate * 100}%</span>,
    },
    {
      field: 'expiresIn',
      headerName: 'Dias para expirar',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => renderExpireDays(params.row.expiresIn),
    },
    {
      field: 'slug',
      headerName: 'Slug',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 220,
      renderCell: (params) => renderVaultSlug(params.row.slug),
    },
    {
      field: 'active',
      headerName: 'Status do cofre',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => renderActiveVault(params.row.active),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 3 }}>
        {vaults?.length > 0 ? (
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              key="giftsPurchases"
              rowCount={rowCount}
              rows={vaults}
              columns={columns}
              loading={refreshDataLoading}
              getRowId={(row) => row.id}
              pageSize={10}
              rowsPerPageOptions={[4]}
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
            />
          </Card>
        ) : (
          <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
        )}
      </CardContent>
    </Styled.Container>
  )
}

export { VaultList }
