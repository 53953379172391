import { Link } from 'react-router-dom'
import { purple } from '@mui/material/colors'
import styled from 'styled-components'

export const LinkMenu = styled(Link)`
  color: #fff !important;
  text-decoration: none;
`
export const LinkOldBackoffice = styled.a`
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: 6rem;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
  background-color: #121212;
  border-radius: 0.25rem;
  padding: 0.25rem;
  &:hover {
    trasition: 2s;
    color: #2196f3;
  }
`

export const FooterContainer = styled.div`
  position: fixed;
  width: 220px;
  height: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0.5rem 0.75rem;
`

export const Divider = styled.div`
  border-top: 0.5px solid ${purple.A700};
  margin-bottom: 0.75rem;
`

export const Footer = styled.span`
  color: #fff;
  text-decoration: none;
  bottom: 2rem;
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
`

export const FooterLink = styled.a`
  color: ${purple[300]};
  text-decoration: none;
  align-self: center;
  font-weight: normal;
  font-size: 0.75rem;
  &:hover {
    color: ${purple[200]};
  }
`
