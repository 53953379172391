import { useEffect, useState } from 'react'

// SERVICES
import { getTrades, getTradeStatus } from '@services/Players/TradesService'

// HELPERS
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import { LoadingButton } from '@mui/lab'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Card, Grid, TextField, Box, Button } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { Modal } from './Modal/Modal'

// ICONS
import { PersonSearch } from '@mui/icons-material'

// TYPES
import { Trades, GetTradesResponse, GetStatusTradesResponse } from './types'
import { ModalParams } from '@components/Players/PlayerTradeList/Modal/types'

// STYLES
import * as Styled from './styles'
import { purple } from '@mui/material/colors'

const PlayerTradeList = (): JSX.Element => {
  const [trades, setTrades] = useState<Trades[]>()
  const [searchText, setSearchText] = useState('')
  const [tradeInfo, setTradeInfo] = useState<ModalParams>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)



  const _getTrades = async (): Promise<void> => {
    try {
      setLoading(true)
      const tradesData: GetTradesResponse = await getTrades(searchText)
      setTrades(tradesData.lastTrades)
    } finally {
      setLoading(false)
    }
  }

  const _getTradeStatus = async (id: string): Promise<void> => {
    const tradeStatus: GetStatusTradesResponse = await getTradeStatus(id)
    setTradeInfo(tradeStatus)
  }

  const handleSearchSubmit = (): void => {
    _getTrades()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = (id: string): void => {
    _getTradeStatus(id)
    setOpen(true)
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <Button variant="contained" onClick={() => handleClick(id)}>
        Status
      </Button>
    )
  }

  const renderItemImage = (itemImage): JSX.Element | null => {
    if (!itemImage.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Item"
        src={itemImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderVaultImage = (vaultImage): JSX.Element | null => {
    if (!vaultImage.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Cofre"
        src={vaultImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderDataGrid = (): JSX.Element => {
    if (!trades)
      return <p>Busque uma lista de trocas usando o gcId do player acima</p>

    if (trades.length === 0)
      return <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />

    return (
      <DataGrid
        paginationMode="server"
        rows={trades}
        columns={columns}
        getRowId={(row) => row.dateOpened}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
        rowHeight={80}
        disableSelectionOnClick
        sx={{
          ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
            outline: 'none',
          },
          ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
            whiteSpace: 'break-spaces',
          },
        }}
      />
    )
  }

  const columns: GridColumns = [
    {
      field: 'matchId',
      headerName: 'Id da partida',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'isOpen',
      headerName: 'Cofre aberto',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'neshaTradeId',
      headerName: 'Nesha Trade ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
    },
    {
      field: 'itemName',
      headerName: 'Nome da Skin',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 125,
    },
    {
      field: 'itemImage',
      headerName: 'Imagem do drop',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 145,
      renderCell: (params) => renderItemImage(params.row.itemImage),
    },
    {
      field: 'vaultName',
      headerName: 'Cofre',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
    },
    {
      field: 'vaultImage',
      headerName: 'Imagem do cofre',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => renderVaultImage(params.row.vaultImage),
    },
    {
      field: 'dateOpened',
      headerName: 'Data de Abertura',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => formatDate(params.row.dateOpened),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.neshaTradeId),
    },
  ]

  useEffect(() => {
    searchText && handleSearchSubmit()
  }, [])

  return (
    <Styled.Container>
      <HeaderTitle title="Lista trades">
        <AssignmentRoundedIcon fontSize="large" style={{ fill: purple[500] }} />
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="Buscar"
          variant="outlined"
          onChange={(e) => setSearchText(e.target.value)}
          disabled={loading}
          placeholder="GC ID do player"
          value={searchText}
        />
        <LoadingButton
          variant="contained"
          onClick={(): void => handleSearchSubmit()}
          disabled={loading || !searchText}
          loading={loading}
        >
          <PersonSearch /> {!searchText ? 'Digite um GC ID' : 'Buscar'}
        </LoadingButton>
      </Styled.SearchForm>
      {renderDataGrid()}
      <Modal handleClose={() => handleClose()} open={open} params={tradeInfo} />
    </Styled.Container>
  )
}

export { PlayerTradeList }
