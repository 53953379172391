import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  createStyle,
  deleteStyle,
  getStyle,
  updateStyle
} from '@services/Vault/VaultStylesService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Card,
  Button,
  Box,
  Chip,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// TYPES
import {
  initialVaultStyleState,
  VaultStyle
} from '@shared/types/VaultStylesTypes'

const VaultStylesForm = (): JSX.Element => {
  const [style, setStyle] = useState<VaultStyle>(initialVaultStyleState)

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getStyle(id)
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updateStyle() 
    } else {
      _createStyle()
    }
  }

  const _createStyle = async (): Promise<void> => {
    try {
      await createStyle(style)
      Alert.success(
        'Estilo criado com sucesso',
        navigate('/apps/vault/style/list') 
      )
    } catch {
      Alert.error('Ocorreu um erro criar o estilo')
    }
  }

  const _updateStyle = async (): Promise<void> => {
    try {
      await updateStyle(style, parseInt(id))
      Alert.success(
        'Estilo atualizado com sucesso',
        navigate('/apps/vault/style/list') 
      )
    } catch {
      Alert.error('Ocorreu um erro atualizar o estilo')
    }
  }

  const _getStyle = async (id: string): Promise<void> => {
    try {
      const styleData = await getStyle(id)
      if (styleData) {
        setStyle(styleData)
      }
    } catch(err) {
      Alert.error('Ocorreu um erro ao carregar o estilo')
    }
  }

  const _deleteStyle = async (id: number): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir esse estilo?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteStyle(id)
          Alert.success(
            'Estilo deletado com sucesso!',
            navigate('/apps/vault/style/list')
          )
        }
      })
      .catch((e) => {
        Alert.error('Erro ao delete o estilo')
      })
  }
  const validateFields = (): string[] => {
    const errors = []
    const {
      priority
    } = style
    if (priority < 1) errors.push('O campo "Prioridade" não pode ser zero')
    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Estilo`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const updateStyleState = (
    key: string,
    value: string | number
  ): void => {
    setStyle(previous => {
      return {
        ...previous,
        [key]: value
      }
    })
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">

            <Grid item xs={2}>
              <TextField
                required
                label="Nome"
                variant="outlined"
                fullWidth
                onChange={(e) => updateStyleState('name', e.target.value)}
                placeholder="Apenas numeros"
                value={style.name}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Cor"
                variant="outlined"
                fullWidth
                onChange={(e) => updateStyleState('color', e.target.value)}
                placeholder="red, #ff0000, rgb(255, 0, 0)"
                value={style.color}
              />
              <Chip
                sx={{
                  backgroundColor: style.color,
                  marginTop: '2px',
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Prioridade"
                variant="outlined"
                fullWidth
                onChange={(e) => updateStyleState('priority', parseInt(e.target.value))}
                placeholder="Apenas numeros"
                value={style.priority}
              />
            </Grid>

            <Grid item xs={2} sx={{ mt: 2 }}>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  color="error"
                  type="button"
                  variant="contained"
                  disabled={!id}
                  sx={{ mr: 2 }}
                  onClick={() => _deleteStyle(parseInt(id))}
                >
                  Deletar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { VaultStylesForm }
