import React, { useState, useEffect } from 'react'

// TYPES
import { VaultItemsProps } from './types'

// STYLES
import * as Styled from './VaultItems.styles'

const VaultItems = ({  
  skins,
  cosmetics,
  tiers,
  setSelectedItems
}: VaultItemsProps) => {

  const allItems = [...cosmetics, ...skins]

  const [
    items,
    setItems
  ] = useState([])

  useEffect(() => {
    setItems(allItems)
  }, [skins, cosmetics])

  useEffect(() => {
    setSelectedItems(items)
  }, [items])

  const updateItemTier = (index, tier) => {
    const item = items[index]

    item.tier = parseInt(tier)

    setItems([...items])
  }

  const updateItemStock = (index, stock) => {
    const item = items[index]

    item.stock = parseInt(stock)

    setItems([...items])
  }

  const removeitem = (removeItem) => {
    if (removeItem.typeItem >= 1) {
      setItems([...items.filter(item => item.id !== removeItem.id)])
    } else {
      setItems([...items.filter(item => item.slug !== removeItem.slug)])
    }
  }

  return (
    <Styled.ListContainer>
    {items.length
      ? items.map((item, i) => (
      <Styled.ListItem key={`${item.slug}-${i}`}>
        <Styled.Slug>
          {`${item.slug} (quant:${item.quantity})`}
        </Styled.Slug>
        <Styled.Label>
          Tier
          <Styled.Select
            value={item.tier}
            onChange={(e) => { updateItemTier(i, e.target.value) }}
          >
            {tiers.map(tier => <option
              key={tier.tier}
              value={tier.tier}>
                {tier.tier} ({tier.dropRate}%)
              </option>
            )}
          </Styled.Select>
        </Styled.Label>
        <Styled.Label>
          Estoque
          <Styled.Input
            type="number"
            value={item.stock}
            onChange={(e) => { updateItemStock(i, e.target.value) }}
          />
        </Styled.Label>
        <Styled.Button
          type="button"
          onClick={() => { removeitem(item) }}
        >
          x
        </Styled.Button>
      </Styled.ListItem>
      ))
      : <Styled.ListItemTitle>Adicione todos os itens no cofre antes de editar tiers e estoques</Styled.ListItemTitle>
    }
    </Styled.ListContainer>
  )
}

export { VaultItems }

