import { useEffect, useState } from 'react'

// STYLES
import * as Styled from './styles'

// SERVICES
import { getDrops, getAllPlayers } from '@services/Players/DropsService'

// TYPES
import { DropsVault, GetDropsResponse, GetDropsQueryParams, Item, Vault } from './types'

// Helper
import { formatDate } from '@shared/utils/FormatDate'


// Components
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'
import { Card, Grid, TextField, Box, LinearProgress } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

import { Person as PersonIcon } from '@mui/icons-material'

const LastDropsList = (): JSX.Element => {
  const [drops, setDrops] = useState<DropsVault[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [firstLoading, setFirstLoading] = useState(true)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10,
    }
    _getAllPlayers(queryParams)
  }, [])

  useEffect(() => {
    if (searchText) {
      _getDrops()
    }
  }, [])

  const _getDrops = async (): Promise<void> => {
    const dropsData: GetDropsResponse = await getDrops(searchText)
    setDrops(dropsData.users)
  }

  const _getAllPlayers = async (
    queryParams: GetDropsQueryParams
  ): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const allPlayers: GetDropsResponse = await getAllPlayers(queryParams)
      setDrops(allPlayers.users)
      setRowCount(allPlayers.total)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    _getDrops()
  }

  const renderItemImage = (item: Item): JSX.Element | null => {
    if (!item || item.image === null) {
      return <Styled.ItemText>Cofre fechado</Styled.ItemText>
    }

    return (
      <>
        <Styled.ItemImage
          alt="Imagem do Item"
          src={item.image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src =
              'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
          }}
        />
        <Styled.ItemText>{item.name}</Styled.ItemText>
      </>
    )
  }

  const renderDateOpened = (date): JSX.Element | string => {
    if (!date) {
      return <p>Cofre fechado</p>
    }

    return formatDate(date)
  }

  const renderVaultImage = (vault: Vault): JSX.Element | null => {
    if (!vault || vault.image === null) {
      return (
        <Styled.ItemImage
          alt="Imagem do Item"
          src="https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg"
        />
      )
    }

    return (
      <>
        <Styled.ItemImage
          alt="Imagem do Item"
          src={vault.image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src =
              'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
          }}
        />
        <Styled.ItemText>{vault.name}</Styled.ItemText>
      </>
    )
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const columns: GridColumns = [
    { field: 'id', hide: true },
    {
      field: 'playerId',
      headerName: 'Jogador',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
    },
    {
      field: 'vault',
      headerName: 'Cofre',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      minWidth: 300,
      renderCell: (params) => renderVaultImage(params.row.vault),
    },
    {
      field: 'item',
      headerName: 'Item',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      minWidth: 300,
      renderCell: (params) => renderItemImage(params.row.item),
    },
    {
      field: 'createdAt',
      headerName: 'Dropado em',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) => formatDate(params.row.createdAt),
    },
    {
      field: 'dateOpened',
      headerName: 'Aberto em',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) => renderDateOpened(params.row.dateOpened),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Drops">
        <PersonIcon fontSize="large" />
      </HeaderTitle>
      <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
        <Grid container mt={4} columnSpacing={2}>
          <Grid item xs={7} lg={8}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              size={'small'}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Styled.CustomButton variant="contained" type="submit">
                Buscar
              </Styled.CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Styled.FullWidthForm>
      {drops?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none',
            },
          }}
        >
          <DataGrid
            paginationMode="server"
            rowCount={rowCount}
            rows={drops}
            columns={columns}
            loading={refreshDataLoading}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            rowHeight={80}
            disableSelectionOnClick
            components={{
              LoadingOverlay: renderCustomLoadingOverlay,
            }}
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none',
              },
              ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                whiteSpace: 'break-spaces',
              },
            }}
            onPageChange={(page): Promise<void> =>
              _getAllPlayers({
                page: page + 1,
                limit: 10,
              })
            }
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { LastDropsList }
