import React, { useState, useEffect } from 'react'

// STYLES
import * as Styled from './VaultTiers.styles'

const VaultTiers = (props) => {
  const {
    setSelectedTiers,
    vaultTiers
  } = props

  const [
    tiers,
    setTiers
  ] = useState([])

  const [
    tierSum,
    setTierSum
  ] = useState(0)

  useEffect(() => {
    if (tiers.length === 0) return

    setSelectedTiers(tiers)
    updateTierSum()
  }, [tiers])

  useEffect(() => {
    setTiers(vaultTiers)
    updateTierSum()
  }, [vaultTiers])

  const addTier = (e) => {
    setTiers([...tiers, { tier: tiers.length, dropRate: 0 }])
  }

  const removeTier = (id) => {
    setTiers(tiers.filter(tier => tier.tier !== id))
  }

  const updateTierSum = () => {
    const dropRates = []
    tiers.map(tier => dropRates.push(tier.dropRate))

    setTierSum(dropRates.reduce((a, b) => a + b, 0))
  }

  const updateTierId = (index, id) => {
    const item = tiers[index]

    item.tier = parseInt(id)

    setTiers([...tiers])
  }

  const updateDropRate = (index, dropRate) => {
    const item = tiers[index]

    item.dropRate = parseFloat(dropRate)

    setTiers([...tiers])
  }

  return (
    <Styled.VaultTiers>
      <Styled.VaultTiersHeader>
        <Styled.VaultTiersTitle>Tiers do cofre</Styled.VaultTiersTitle>
        <Styled.VaultTiersAdd
          onClick={(e) => addTier(e)}
          type="button"
        >
          Adicionar tier
        </Styled.VaultTiersAdd>
      </Styled.VaultTiersHeader>
      <Styled.VaultTiersList>
        {tiers.map((tier, i) =>
          <Styled.VaultTiersItem key={i}>
            <Styled.VaultTiersLabel>
              Tier:
              <Styled.VaultTiersInput
                type="number"
                value={tier.tier}
                onChange={(e) => updateTierId(i, e.target.value || '')}
                disabled
              />
            </Styled.VaultTiersLabel>
            <Styled.VaultTiersLabel>
              Porcentagem total <Styled.VaultTiersAttention>*</Styled.VaultTiersAttention>:
              <Styled.VaultTiersInput
                type="number"
                value={tier.dropRate}
                onChange={(e) => updateDropRate(i, e.target.value || '')}
              />
              %
            </Styled.VaultTiersLabel>
            <Styled.VaultTiersDelete
              onClick={() => removeTier(tier.tier)}
              type="button"
            >x</Styled.VaultTiersDelete>
          </Styled.VaultTiersItem>
        )}
      </Styled.VaultTiersList>
      <Styled.VaultTiersNote>
        <span>* Utilize vírgula para números decimais (ex.: 0,25)</span>
        <span>Porcentagem total: { tierSum }%</span>
      </Styled.VaultTiersNote>
    </Styled.VaultTiers>
  )
}

export { VaultTiers }
