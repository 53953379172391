import styled from 'styled-components'
import { Button } from '@mui/material'

export const Container = styled.div`
  width: 100%;
`

export const CustomButton = styled(Button)`
  height: 100%;
`

export const FullWidthForm = styled.form`
  display: flex;
  width: 100%;
`
export const ItemText = styled.p`
  margin-left: 5px;
`
export const ItemImage = styled.img`
  height: 56px;
  width: 56px;
`

export const CustomLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`
