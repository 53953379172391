import { useEffect, useState } from 'react'

// SERVICES
import { getVaults, deliveryVault } from '@services/Vault/VaultService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const VaultDeliveryForm = (): JSX.Element => {
  const [slug, setSlug] = useState<string>('')
  const [notUseItemKey, setNotUseItemKey] = useState<boolean>(false)
  const [vaultSlugs, setVaultSlugs] = useState([])
  const [idsNotFound, setIdsNotFound] = useState<string[]>([])
  const [idsWithoutInfo, setIdsWithoutInfo] = useState<string[]>([])
  const [csvContents, setCsvContents] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const reference = 'ADMIN'

  useEffect(() => {
    getVaults().then((response) => setVaultSlugs(response.vaults))
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    setLoading(true)
    _deliveryVault()
  }

  const handleChange = (event: SelectChangeEvent<typeof slug>) => {
    setSlug(event.target.value)
  }
  const _deliveryVault = async (): Promise<void> => {
    const csvContent = csvContents
      .map((csvContent) =>
        csvContent
          .replace(/[\r'" ]/g, '')
          .replace(/(,+|;+|\s+)/g, '\n')
          .replace(/(\n+)/g, '\n')
          .replace(/^\n|\n$/g, '')
          .split(/[\n]/g)
      )
      .reduce((result, currentValue): string[] => {
        result.push(...currentValue)

        return result
      }, [])

    const payload = {
      playerIds: csvContent,
      slug,
      reference,
      notUseItemKey,
    }
    await deliveryVault(payload)
      .then((response) => {
        setIdsWithoutInfo(response.idsWithoutInfo)
        setIdsNotFound(response.idsNotFound)
        const error =
          response.idsNotFound.length > 0 || response.idsWithoutInfo.length > 0
            ? 'Porém não para alguns ids'
            : ''

        Alert.success(`Cofre entregue com sucesso. ${error}`)
        setCsvContents([])
      })
      .catch((err) => {
        Alert.error('Ocorreu um erro ao realizar a entrega do cofre')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderSelectOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.slug} value={obj.slug}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!csvContents.length) errors.push('A lista de jogadores é obrigatória')
    if (!slug) errors.push('É necessário escolher um cofre para entrega')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Entrega de Cofres">
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const DropdownStyles = {
    PaperProps: {
      style: {
        maxHeight: 500,
      },
    },
  }

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="vault-slug-name-label">Cofre</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={slug}
                  onChange={handleChange}
                  MenuProps={DropdownStyles}
                  input={<OutlinedInput label="Name" />}
                >
                  {renderSelectOptions(vaultSlugs)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={notUseItemKey}
                    onChange={(e) => setNotUseItemKey(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Entregar o cofre aberto"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Use o formato abaixo na mesma sequencia em todos os players:
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                PlayerID
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                PlayerID
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                PlayerID
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Lista de jogadores"
                variant="outlined"
                fullWidth
                multiline={true}
                rows={5}
                onChange={(e) => setCsvContents([e.target.value])}
                value={csvContents}
              />
              <Grid item xs={12} sx={{ mt: 2 }}>
                {idsWithoutInfo.length > 0 && (
                  <Typography
                    variant="button"
                    display="block"
                    color="red"
                    gutterBottom
                  >
                    Ids sem accountId/credential: {idsWithoutInfo}
                  </Typography>
                )}
                {idsNotFound.length > 0 && (
                  <Typography
                    variant="button"
                    display="block"
                    color="red"
                    gutterBottom
                  >
                    Ids não encontrados: {idsNotFound}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box
              display="flex"
              justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            >
              <Button variant="contained" type="submit" disabled={loading}>
                {loading ? 'Enviando os cofres' : 'Entregar Cofre'}
              </Button>
            </Box>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { VaultDeliveryForm }
