// API
import { vaultApi } from '@shared/api/Vault/VaultApi'

// TYPES
import {
  VaultStyle,
  VaultStyleResponse
} from '@shared/types/VaultStylesTypes'

export const getStyles = (): Promise<VaultStyle[]> => {
  return vaultApi.get('/api/vaultStyle/')
}

export const getStyle = (id: string): Promise<VaultStyle> => {
  return vaultApi.get(`/api/vaultStyle/${id}`)
}

export const createStyle = (data: VaultStyle): Promise<VaultStyle> => {
  return vaultApi.post('/api/vaultStyle/', data)
}

export const updateStyle = (vaultStyle: VaultStyle, vaultStyleId: number): Promise<VaultStyle> => {
  return vaultApi.put(`/api/vaultStyle/${vaultStyleId}`, vaultStyle)
}

export const deleteStyle = (id: number): Promise<any> => {
  return vaultApi.delete(`/api/vaultStyle/${id}`)
}
