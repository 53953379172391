import styled from 'styled-components'

export const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px 20px;
  background-color: #fafafa;
  border: 1px solid #eee;
`

export const ListItem = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;

  &:last-of-type {
    border: 0;
  }
`

export const ListItemTitle = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
`

export const Slug = styled.span`
  width: 30%;
  color: #666;
`

export const Label = styled.label`
  display: block;
  color: #666;
`

export const Select = styled.select`
  display: inline-block;
  margin: 0 5px;
  padding: 3px;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: transparent;
  border-width: 0 0 1px 0;
  border-color: #ccc;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Input = styled.input`
  display: inline-block;
  margin: 0 5px;
  padding: 3px;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: transparent;
  border-width: 0 0 1px 0;
  border-color: #ccc;

  &[type="number"] {
    max-width: 60px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Button = styled.button`
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border: 2px solid #d9534f;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  color: #d9534f;
  cursor: pointer;

  &:hover {
    background-color: #d9534f;
    color: white;
  }
`