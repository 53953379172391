import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  width: 377px;
`
export const ImageContainer = styled.figure`
  width: 160px;
  margin: 0 auto 15px;
`
export const Image = styled.img`
  display: block;
  width: 100%;
`

export const Name = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`

export const Slug = styled.p`
  font-size: 14px;
  color: #06C;
  margin-bottom: 10px;
`

export const Description = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  `
