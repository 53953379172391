import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
`
export const SearchForm = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 10px 0;

  .MuiInputBase-root {
    min-width: ${(360 * 100) / 1920}vw;
    margin-right: 10px;
  }

  .MuiButton-root {
    margin-right: 10px;
  }
`

export const Link = styled.a`
  color: white;
`

export const ProductImage = styled.img`
  height: 56px;
  width: 56px;
`
