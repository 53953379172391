import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// SERVICES
import { 
  createItem,
  getItems,
  updateItem
} from '@services/Items/ItemsService'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
  Divider,
  Stack,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { ProductCard } from '@components/shared/ProductCard/ProductCard'

const CoinsItemsForm = (): JSX.Element => {
  const [productId, setProductId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [stock, setStock] = useState<number>(99999);
  const [quantity, setQuantity] = useState<number>(1);
  const [slug, setSlug] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [typeItem, setTypeItem] = useState<number>(0);

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getCoinsItems()
    }
  }, [])

  const coinsList = [
    {
      id: 2,
      name: 'gold',
      url: 'https://assets.gamersclub.com.br/csgo-frontend/static/media/icon-hard-coin.d31c7fe1.png'
    },
    {
      id: 3,
      name: 'silver',
      url: 'https://assets.gamersclub.com.br/csgo-frontend/static/media/icon-soft-coin.d3c7e147.png'
    }
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (id) {
      _updateCoinsItems() 
    } else {
      _createCoinsItems()
    }
  }

  const _createCoinsItems = async (): Promise<void> => {
    try {
      const s = quantity === 1 ? '' : 's'

      const payload = {
        name: `${quantity} ${name}${s}`,
        description,
        stock,
        quantity,
        image,
        slug: slug,
        tier: null,
        typeItem,
      }

      await createItem(payload)

      Alert.success(
        'Item de cofre criado com sucesso!',
        navigate('/apps/vault/items/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao criar o item de cofre')
    }
  }

  const _updateCoinsItems = async (): Promise<void> => {
    try {
      const payload = {
        id,
        name,
        description,
        stock,
        quantity,
        image,
        slug: slug,
        tier: null,
        typeItem,
      }

      await updateItem(payload)

      Alert.success(
        'Item de cofre editado com sucesso!',
        navigate('/apps/vault/items/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao editar o item de cofre')
    }
  }

  const _getCoinsItems = async (): Promise<void> => {
    try {
      const itemData = await getItems(id)

      if (itemData) {
        setName(itemData.item.name)
        setDescription(itemData.item.description)
        setStock(itemData.item.stock)
        setQuantity(itemData.item.quantity)
        setSlug(itemData.item.slug)
        setImage(itemData.item.image)
        setTypeItem(itemData.item.typeItem)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar item de cofre')
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Coin`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const renderCoinsOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const currentProductCard = () => {
    if(!name) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 1, width: '377px' }}>
          Sem moeda selecionado
        </Grid>
      )
    }

    return (
      <ProductCard 
        imageUrl={image}
        name={name}
        slug={slug}
        description={description}
      />
    )
  }

  const handleSelectCurrentProduct = (id, coinsObj) => {
    const coinSelect = coinsObj.find(coin => (coin.id === id))
    setProductId(id),
    setName(coinSelect.name),
    setDescription(`coin ${coinSelect.name}`),
    setImage(coinSelect.url),
    setSlug(`${coinSelect.name}-${quantity}`),
    setTypeItem(id)
  }

  const handleQuantity = (e) => {
    setQuantity(e.target.value),
    setSlug(`${name}-${e.target.value}`)
  }

  return (
    <Card sx={{ width: 800 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{p: 4}}>
        <form onSubmit={handleSubmit}> 
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <Box>
              <Grid container spacing={2}>
                {!id &&
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel shrink>Selecione uma coin</InputLabel>
                      <Select
                        required
                        fullWidth={true}
                        value={productId}
                        onChange={(e) => handleSelectCurrentProduct(e.target.value, coinsList)}
                        input={<OutlinedInput notched label="Selecione uma coin" />}
                      >
                        {renderCoinsOptions(coinsList)}
                      </Select>
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    label="Quantidade"
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    helperText={id ? 'Não é possível editar a quantidade' : ''}
                    disabled={!!id}
                    placeholder="Apenas numeros"
                    fullWidth
                    onChange={(e) => handleQuantity(e)}
                    value={quantity}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Estoque"
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    placeholder="Apenas numeros"
                    fullWidth
                    onChange={(e) => setStock(Number(e.target.value))}
                    value={stock}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ ml: 2, width: '377px'}} alignItems="center" justifyContent="center">
              <Grid container direction="column" alignItems="center" justifyContent="space-between" sx={{ height: '100%' }}>
                {currentProductCard()}
              </Grid>
            </Box>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs sx={{ mt: 5 }}>
              <Box
                display="flex"
                justifyContent={`${id ? 'space-between' : 'flex-end'}`}
                >
                <Button variant="contained" type="submit">
                  {buttonLabelAction} Coins
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { CoinsItemsForm }
