import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import { getStyles } from '@services/Vault/VaultStylesService'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Chip,
  Button,
  LinearProgress,
  Card
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'
import { NotFound } from '@components/shared/NotFound/NotFound'

// TYPES
import {
  VaultStyle,
  VaultStyleListResponse
} from '@shared/types/VaultStylesTypes'

// ICONS
import { ReportOutlined as ReportIcon } from '@mui/icons-material'

// STYLES
import * as Styled from './VaultStylesList.styles'

const VaultStylesList = (): JSX.Element => {
  const [styles, setStyles] = useState<VaultStyle[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    _getVaultsStyles()
  }, [])

  const _getVaultsStyles = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const stylesData: VaultStyle[] = await getStyles()
      setStyles(stylesData.sort((a, b) => a.priority < b.priority ? -1 : 1))
      setRowCount(stylesData.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Listagem de estilos">
        <ReportIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <Button
        onClick={(): void => navigate(`/apps/vault/style/${id}`)}
        color="primary"
        variant="contained"
      >
        Editar
      </Button>
    )
  }

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Nome',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 220,
    },
    {
      field: 'color',
      headerName: 'Cor',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: (params) => <Chip label={params.row.color} sx={{ backgroundColor: params.row.color }} />
    },
    {
      field: 'priority',
      headerName: 'Prioridade',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 3 }}>
        {styles?.length > 0 ? (
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              key="giftsPurchases"
              rowCount={rowCount}
              rows={styles}
              columns={columns}
              loading={refreshDataLoading}
              getRowId={(row) => row.id}
              pageSize={10}
              rowsPerPageOptions={[4]}
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
            />
          </Card>
        ) : (
          <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
        )}
      </CardContent>
    </Styled.Container>
  )
}

export { VaultStylesList }
